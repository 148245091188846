.tabs {
    position: relative;
}

.tabsWrapper {
    position: relative;
    padding: 8px 16px;
    margin-bottom: 64px;
    background-color: #f9f9f9;
    border-radius: 10px;
    width: max-content;
    z-index: 1;
}

.line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #707070;
    z-index: 0;
}

.tabsRoot {
    position: relative;
    width: max-content;
    z-index: 1;
}

.backTabs {
    position: absolute;
    top: 0;
    width: max-content;
    display: flex;
    justify-content: flex-start;
    padding: 16px 8px;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background-color: #2b2b2b;
    border-radius: 16px;

    span {
        padding: 8px 16px;
        font-size: 16px;
        line-height: 125%;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
    }
}

.tabsList {
    position: relative;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background-color: #f9f9f9;
    border-radius: 10px;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        display: none;
    }
}

.tab {
    position: relative;
    border: none;
    border-radius: 10px;
    padding: 8px 16px;
    transition: font-weight 0.3s ease;
    background-color: transparent;
    width: fit-content;
    flex-shrink: 0;

    &:hover {
        cursor: pointer;
    }
}

.tabTitle {
    position: relative;
    z-index: 10;
    font-size: 16px;
    line-height: 125%;
    font-family: 'Poppins', sans-serif;
}

.overlay {
    background-color: #2b2b2b;

    button {
        color: #ffffff;
    }
}

.bubble {
    position: absolute;
    inset: 0;
    clip-path: inset(0px);
    border-radius: 8px;
}

.clipPathContainer {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    transition: clip-path 0.25s ease;
    clip-path: inset(0px 65% 0px 0% round 10px);
}
