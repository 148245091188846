.spinner {
    height: 48px;
    width: 48px;
    border-radius: 9999px;
    border-top: 4px solid #2b2b2b;
    border-right: 4px solid #2b2b2b;
    border-bottom: 4px solid #cdcdcd;
    border-left: 4px solid #cdcdcd;
    animation: rotate 1s ease-in-out infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
