.tab {
    padding: 8px 16px;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    width: min-content;
}

.paid {
    background-color: #e2fff3;
    color: #2eb67d;
}

.unpaid {
    background-color: #ffd0d0;
    color: #b21d1d;
}
