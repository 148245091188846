@import './../../../../Style/theme.scss';

.AccurateSizingAndFit {
    background-color: #000000;
    padding: 120px 16px 160px;

    .wrapper-content {
        display: flex;
        gap: 120px;

        .accurate-sizing-image {
            margin-left: auto;
            max-width: 100%;
            height: 600px;
        }

        .info {
            display: flex;
            flex-direction: column;
            margin-right: auto;

            .top {
                margin-right: auto;
                flex-grow: 1;
            }

            .bottom {
                color: #ffffffbf !important;
                display: flex;
                flex-direction: column;
                gap: 8px;
                max-width: 497px;
                margin-right: auto;

                .text-description {
                    margin-top: 24px;
                    color: #ffffffbf !important;
                }

                .subtitle {
                    color: #ffffffbf !important;
                    font-weight: 700;
                }

                bold {
                    color: #ffffff !important;
                }

                .disclaimer {
                    margin-top: 24px;
                    color: #ffffffbf !important;
                }

                @media (max-width: 1000px) {
                    max-width: 300px;
                }
            }
        }

        @media (max-width: 1000px) {
            gap: 60px;
        }

        @media (max-width: 724px) {
            gap: 24px;
            flex-direction: column;

            .bottom {
                max-width: 100%;
                margin-right: 0;
            }

            .accurate-sizing-image {
                margin-right: auto;
                margin-left: auto;
            }

            .info {
                margin-left: auto;
                margin-top: 10px;
            }
        }

        @media (max-width: 450px) {
            .accurate-sizing-image {
                height: auto;
                max-height: 600px;
                width: auto;
            }
        }
    }

    @media (max-width: 1000px) {
        padding: 80px 16px;
    }
}
