.shepard-element {
    border-radius: 20px;
    padding: 16px;
    border: none;
    width: 310px;
}

.shepherd-button {
    border-radius: 50px;
    border: 1px solid #2b2b2b;
    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #2b2b2b;
    font-weight: 700;

    &:hover {
        background-color: #2b2b2b !important;
        color: #f9f9f9 !important;
    }
}

.shepherd-header {
    background-color: #ffffff !important;
    padding: 0 !important;
    padding-bottom: 12px !important;

    h3 {
        font-weight: 700;
    }
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
    opacity: 0.3;
}

.shepherd-text {
    padding: 0 !important;
    padding-bottom: 24px !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 125%;
    color: #000;

    span {
        font-weight: 700;
    }
}

.shepherd-footer {
    justify-content: flex-start;
    padding: 0 !important;
}

.shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow:before {
    background-color: #ffffff;
}

.shepherd-count {
    position: absolute;
    bottom: 24px;
    right: 24px;

    color: #000;

    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
}
