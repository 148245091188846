.employeePortal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: calc(100vh - 80px);
}

.subtitle {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 8px;
}

.subheading {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.left {
    display: grid;
    grid-template-rows: min-content 1fr;
    justify-content: center;
    padding-top: 105px;
    padding-bottom: 150px;
    padding-left: 24px;
    padding-right: 24px;
    gap: 64px;

    .qrCodeContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .qrCode {
        height: 340px;
        width: 340px;
    }
}

.cardContainer {
    display: inline-flex;
    padding: 64px;
    max-width: 584px;
    height: min-content;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 54px;
    max-width: 459px;
}

.bgGreen {
    border-radius: 30px;
    background: rgba(46, 182, 125, 0.05);
}

.right {
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.07));
    background-color: #fff;
    display: grid;
    grid-template-rows: min-content 1fr;
    justify-content: center;
    align-items: center;
    gap: 64px;
    padding-top: 105px;
    padding-bottom: 150px;
    padding-left: 24px;
    padding-right: 24px;
    height: 100%;

    .list {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            display: flex;
            gap: 10px;
            align-items: flex-start;
            border-radius: 8px;

            svg {
                flex-shrink: 0;
            }
        }
    }
}

.removeBg {
    filter: none;
    background-color: transparent;
}

.content {
    h3 {
        margin-top: 24px;
        margin-bottom: 32px;
    }
}
