.emptyState {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    margin: 0 auto;
    text-align: center;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: min(100%, 522px);

        h4 {
            margin-top: 10px;
            margin-bottom: 18px;
        }
    }
}

.errorContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    margin: 0 auto;
    text-align: center;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: min(100%, 467px);

        h4 {
            margin-top: 10px;
            margin-bottom: 18px;
        }
    }
}

.invoicesTable {
    position: relative;
    min-height: 500px;
}

.table {
    display: inline-table;
    border-collapse: collapse;
    width: 100%;
}

.header {
    text-align: left;
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 24px;
}

.cell {
    text-align: left;
    color: rgba(63, 63, 63, 0.75);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 24px 0;
    height: 100px;
}

.row {
    &:not(:last-child) {
        border-bottom: 1px solid rgba(63, 63, 63, 0.25);
    }
}

.tableHeader {
    border-bottom: 1px solid rgba(63, 63, 63, 0.25);
    padding: 0;

    &:nth-of-type(1) {
        width: 20%;
    }

    &:nth-of-type(2) {
        width: 30%;
    }

    &:nth-of-type(3) {
        width: 10%;
    }

    &:nth-of-type(4) {
        width: 10%;
    }

    &:nth-of-type(5) {
        width: 15%;
    }
}

.name {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.loadingContianer {
    position: absolute;
    width: 100%;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    padding: 12px 16px !important;
    flex-shrink: 0 !important;
    white-space: nowrap;
}

.noInvoices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    height: 100%;
    padding-top: 120px;
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
}

.loadingContianer {
    width: 100%;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    path {
        stroke: black;
        stroke-width: '1px';
    }
}
