.statusBadge {
    display: inline-flex;
    padding: 8px 16px;
    align-items: center;
    border-radius: 50px;

    p {
        color: inherit;
        font-weight: 600;
        text-transform: capitalize;
        font-size: 16px;
    }
}

.incomplete {
    background-color: #fce8e9;
    color: #e41c24;
}

.completed {
    background-color: #ebfaf3;
    color: #2eb67d;
}

.processing {
    background-color: #faeaf7;
    color: #9f2589;
}
